<template>
  <div>
    <b-card>
      <b-card-text>
        <b-row>
          <b-col cols="12" md="4"
            ><b-form-group label="Cinema/Theatre">
              <b-form-input
                v-model="form.cinema_name"
                id="input-default"
                placeholder="Cinema/Theatre Name"
              ></b-form-input> </b-form-group
          ></b-col>
          <b-col cols="12" md="4"
            ><b-form-group label="Country">
              <v-select
                @input="filterCities"
                :clearable="false"
                v-model="form.country_id"
                label="country_name"
                value="country_id"
                :reduce="(country) => country.country_id"
                placeholder="Select Country"
                :options="countryListArray"
              /> </b-form-group
          ></b-col>
          <b-col cols="12" md="4"
            ><b-form-group label="City">
              <v-select
                v-model="form.city_id"
                placeholder="Select City"
                :clearable="false"
                label="city_name"
                value="city_id"
                :reduce="(city) => city.city_id"
                :options="filteredCityListArray"
              /> </b-form-group
          ></b-col>

          <b-col cols="12" md="8"
            ><b-form-group label="Cinema Address">
              <quill-editor v-model="form.cinema_address">
              </quill-editor> </b-form-group
          ></b-col>

          <b-col cols="12" md="4"
            ><b-form-group label="Cinema Zip Code">
              <b-form-input
                v-model="form.cinema_pincode"
                id="input-default"
                placeholder="Cinema Zip Code"
              ></b-form-input></b-form-group
          ></b-col>
          <b-col cols="12" md="4"
            ><b-form-group label="Cinema Email">
              <b-form-input
                v-model="form.cinema_email"
                id="input-default"
                placeholder="Cinema Email"
              ></b-form-input></b-form-group
          ></b-col>
          <b-col cols="12" md="4"
            ><b-form-group label="Cinema Latitude">
              <b-form-input
                v-model="form.cinema_lat"
                id="input-default"
                placeholder="Cinema Latitude"
              ></b-form-input> </b-form-group
          ></b-col>
          <b-col cols="12" md="4"
            ><b-form-group label="Cinema Longitude">
              <b-form-input
                v-model="form.cinema_long"
                id="input-default"
                placeholder="Cinema Longitude"
              ></b-form-input> </b-form-group
          ></b-col>
          <b-col cols="12" md="4"
            ><b-form-group label="Cinema Contact Person">
              <b-form-input
                v-model="form.cinema_cont_per_name"
                id="input-default"
                placeholder="Cinema Contact Person"
              ></b-form-input> </b-form-group
          ></b-col>
          <b-col cols="12" md="4"
            ><b-form-group label="Cinema Contact Person Number">
              <b-form-input
                v-model="form.cinema_cont_per_number"
                id="input-default"
                placeholder="Cinema Contact Person Number"
              ></b-form-input> </b-form-group
          ></b-col>
          <b-col cols="12" md="4"></b-col>
          <b-col cols="12" md="4"
            ><b-form-group label="Cinema Timezone">
              <v-select
                :clearable="false"
                v-model="form.timezone_id"
                label="tz_name"
                value="tz_id"
                :reduce="(timezone) => timezone.tz_id"
                placeholder="Select Timezone"
                :options="timezoneListArray"
              /> </b-form-group
          ></b-col>
          <b-col cols="12" md="4"
            ><b-form-group label="Cinema Event Currency">
              <v-select
                :clearable="false"
                v-model="form.currency_id"
                label="curr_name"
                value="curr_id"
                :reduce="(currency) => currency.curr_id"
                placeholder="Select Currency"
                :options="currencyListArray"
              /> </b-form-group
          ></b-col>
          <b-col cols="12" md="4"
            ><b-form-group label="Cinema Event Payment Gateway Currency">
              <v-select
                :clearable="false"
                v-model="form.pay_currency_id"
                label="curr_name"
                value="curr_id"
                :reduce="(currency) => currency.curr_id"
                placeholder="Select Currency"
                :options="currencyListArray"
              /> </b-form-group
          ></b-col>
          <b-col cols="12" md="4"
            ><b-form-group
              label="Currency Exchange Rate (if event and payment currency are diffrent )"
            >
              <b-form-input
                v-model="form.exchange_rate"
                id="input-default"
                placeholder="Currency Exchange Rate"
              ></b-form-input> </b-form-group
          ></b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Cinema Status">
              <b-form-checkbox v-model="form.cinema_is_active" switch>{{
                form.cinema_is_active == true ? "Active" : "Disabled"
              }}</b-form-checkbox>
            </b-form-group>
          </b-col>

          <b-col class="mt-3" md="6" offset-md="3">
            <b-button block variant="primary" @click="onSubmit"
              >Submit</b-button
            >
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import {
  AddUpdateCinemaService,
  GetCityListService,
  GetCountryListService,
  GetCinemaListService,
  GetCurrencyListService,
  GetTimeZoneListService,
} from "@/apiServices/MasterServices";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  data() {
    return {
      form: {
        org_id: null,
        country_id: null,
        timezone_id: null,
        currency_id: null,
        pay_currency_id: null,
        exchange_rate: 1,
        city_id: null,
        cinema_name: null,
        cinema_address: null,
        cinema_pincode: null,
        cinema_lat: null,
        cinema_long: null,
        cinema_email: null,
        cinema_cont_per_name: null,
        cinema_cont_per_number: null,
        cinema_description: null,
        cinema_seat_release_time: null,
        cinema_is_active: true,
        cinema_id: null,
      },
      countryListArray: [],
      currencyListArray: [],
      timezoneListArray: [],
      cityListArray: [],
      filteredCityListArray: [],
      optionCountry: [
        { title: "Theatrical Rights", value: 1 },
        { title: "Other Rights", value: 2 },
      ],
    };
  },
  components: {
    vSelect,
    flatPickr,
    quillEditor,
  },
  async beforeMount() {
    await this.getCountryList();
    await this.getCityList();
    await this.getCurrencyList();
    await this.getTimeZoneList();
    if (this.$route.query && this.$route.query.cinema_id) {
      await this.getCinemaById(this.$route.query.cinema_id);
    }
  },
  methods: {
    async filterCities() {
      this.filteredCityListArray = [];

      let filter = this.cityListArray.filter((x) => {
        return x.country_id == this.form.country_id;
      });

      if (filter.length) {
        this.filteredCityListArray = filter;
      } else {
        this.filteredCityListArray = [];
      }
    },
    async getCountryList() {
      try {
        const response = await GetCountryListService({
          limit: 1000,
        });
        if (response.data.status) {
          this.countryListArray = response.data.Records.data;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getCityList() {
      try {
        const response = await GetCityListService({
          limit: 1000,
        });
        if (response.data.status) {
          this.cityListArray = response.data.Records.data;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onSubmit() {
      try {
        this.form.cinema_is_active =
          this.form.cinema_is_active == true ? "Y" : "N";
        const response = await AddUpdateCinemaService(this.form);
        if (response.data.status) {
          this.$router.push({
            name: "cinemamaster",
          });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "primary",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getCinemaById(cinema_id) {
      try {
        const response = await GetCinemaListService({
          cinema_id: cinema_id ? cinema_id : 0,
        });

        if (response.data.status) {
          if (response.data.Records && response.data.Records.data) {
            let cinemaArray = response.data.Records.data[0];
            Object.keys(cinemaArray).map((x) => {
              this.form[x] = cinemaArray[x];
              if (x == "cinema_is_active") {
                this.form[x] = cinemaArray[x] == "Y" ? true : false;
              }
            });
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getCurrencyList() {
      try {
        const response = await GetCurrencyListService({
          limit: 1000,
        });
        if (response.data.status) {
          this.currencyListArray = response.data.Records.data;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getTimeZoneList() {
      try {
        const response = await GetTimeZoneListService({
          limit: 1000,
        });
        if (response.data.status) {
          this.timezoneListArray = response.data.Records.data;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
